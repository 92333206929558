import { challengerService, detailService } from '../services'

const state = {
  completed: false,
  info: {
    challengers: [],
    details: null
  }
}

const actions = {
  getChallengers ({ commit }) {
    challengerService.getAll()
      .then(challengers => {
        if (challengers) {
          commit('saveChallengers', challengers)
        }
      })
  },
  getDetails ({ commit }, identifier) {
    detailService.get(identifier)
      .then(details => {
        if (details) {
          commit('saveDetails', details)
        } else {
          commit('saveDetails', null)
        }
      })
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  saveChallengers (state, info) {
    state.completed = true
    state.info.challengers = info
  },
  saveDetails (state, info) {
    state.completed = true
    state.info.details = info
  },
  clear (state) {
    state.completed = false
    state.info = {
      challengers: [],
      details: null
    }
  }
}

export const results = {
  namespaced: true,
  state,
  actions,
  mutations
}
