import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './alert.module'
import { auth } from './auth.module'
import { challenge } from './challenge.store'
import { results } from './results.store'
import { staffSubmission } from './staff.submission.module'
import { totals } from './totals.store'
import { user } from './user.store'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    alert,
    auth,
    challenge,
    results,
    staffSubmission,
    totals,
    user
  }
})
