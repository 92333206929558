import { apiRoute, handleResponse } from '../helpers'

export const detailService = {
  get,
  getImage,
  save
}

function get (identifier) {
  const requestOptions = {
    method: 'GET'
  }

  return fetch(apiRoute() + '/api/v1/details/' + identifier, requestOptions).then(handleResponse)
}

function getImage (identifier, image) {
  const requestOptions = {
    method: 'GET'
  }

  return fetch(apiRoute() + '/api/v1/details/image/' + identifier + '/' + encodeURIComponent(image), requestOptions).then(handleResponse)
}

function save (details) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(details)
  }

  return fetch(apiRoute() + '/api/v1/details/' + details._id, requestOptions).then(handleResponse)
}
