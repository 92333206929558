import { jwtHelper } from './jwt-helper'
import { authService } from '../services'

export function authHeader () {
  let user = jwtHelper.getUser()

  if (user && user.token) {
    if (jwtHelper.isStoredValid()) {
      return { Authorization: 'Bearer ' + user.token }
    } else {
      if (jwtHelper.canRefresh()) {
        user = authService.refresh()
        if (user && user.token) {
          return { Authorization: 'Bearer ' + user.token }
        } else {
          return {}
        }
      } else {
        return {}
      }
    }
  } else {
    return {}
  }
}
