import { apiRoute, authHeader, handleResponse } from '../helpers'

export const staffSubmissionService = {
  get,
  getByIdentifiers,
  getAll,
  getCovid,
  update,
  feedback
}

function get (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/pneumo/' + id, requestOptions).then(handleResponse)
}

function getByIdentifiers (params) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/submissions/' + params.patient + '/' + (params.study ? params.study : null), requestOptions).then(handleResponse)
}

function getAll (filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/submissions/' + filter, requestOptions).then(handleResponse)
}

function getCovid (period) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/pneumo/list/' + period, requestOptions).then(handleResponse)
}

function update (id, labels, tags) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      labels: labels,
      tags: tags
    })
  }

  return fetch(apiRoute() + '/api/v1/submissions/callback/update/' + id, requestOptions).then(handleResponse)
}

function feedback (id, pathologies, other) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      pathologies: pathologies,
      other: other
    })
  }

  return fetch(apiRoute() + '/api/v1/submissions/feedback/update/' + id, requestOptions).then(handleResponse)
}
