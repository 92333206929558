import { challengerService, detailService } from '../services'
import Vue from 'vue'

const state = {
  images: 0,
  time: 0,
  accuracy: 0.00,
  accuracies: [],
  scores: {
    phaseOne: [],
    phaseTwo: []
  }
}

const actions = {
  updateImages ({ commit }) {
    commit('updateImages')
  },
  updateTime ({ commit }, time) {
    commit('updateTime', time)
  },
  updateAccuracy ({ commit }, accuracy) {
    commit('updateAccuracy', accuracy.accuracy)

    challengerService.update({
      _id: accuracy._id,
      time: state.time,
      accuracy: state.accuracy
    })
  },
  updateScore ({ commit }, param) {
    if (param.callService !== false) {
      detailService.save({
        _id: param._id,
        phase: param.phase,
        image: param.score
      })
    }

    if (param.phase === 1) {
      commit('updatePhaseOneScore', param.score)
    } else {
      commit('updatePhaseTwoScore', param.score)
    }
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  updateImages (state) {
    state.images += 1
  },
  updateTime (state, time) {
    state.time += time
  },
  updateAccuracy (state, accuracy) {
    state.accuracies.push(accuracy)
    let total = 0.00
    state.accuracies.forEach(accuracy => {
      total += accuracy
    })
    state.accuracy = total / state.accuracies.length
  },
  updatePhaseOneScore (state, score) {
    const _index = state.scores.phaseOne.findIndex(_record => _record.image === score.image)
    if (_index >= 0) {
      Vue.set(state.scores.phaseOne, _index, score)
    } else {
      state.scores.phaseOne.push(score)
    }
  },
  updatePhaseTwoScore (state, score) {
    const _index = state.scores.phaseTwo.findIndex(_record => _record.image === score.image)
    if (_index >= 0) {
      Vue.set(state.scores.phaseTwo, _index, score)
    } else {
      state.scores.phaseTwo.push(score)
    }
  },
  clear (state) {
    state.images = 0
    state.time = 0
    state.accuracy = 0.00
    state.accuracies = []
  }
}

export const totals = {
  namespaced: true,
  state,
  actions,
  mutations
}
