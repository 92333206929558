const state = {
  phaseOne: [
    {
      image: '/img/phaseOne/1.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 255.36,
              y: 461.472,
              w: 129.125,
              h: 129.276
            },
            name: 'Cysts'
          },
          {
            coords: {
              x: 284.7,
              y: 385.764,
              w: 104.868,
              h: 91.368
            },
            name: 'Cysts'
          },
          {
            coords: {
              x: 585.396,
              y: 341.296,
              w: 95.836,
              h: 136.764
            },
            name: 'Infiltrates'
          },
          {
            coords: {
              x: 680.928,
              y: 457.408,
              w: 93.312,
              h: 71.28
            },
            name: 'Consolidation'
          },
          {
            coords: {
              x: 692.87,
              y: 546.07,
              w: 59.954,
              h: 60.146
            },
            name: 'Cavity'
          }
        ]
      },
      reference: {
        time: 42318,
        diagnosis: 'Staph bronchopneumonia complicated by pneumatoceles',
        pathologies: [
          {
            coords: {
              x: 266,
              y: 456,
              w: 125,
              h: 126
            },
            _id: '647782f6b1dcda0013e4ed32',
            name: 'Cysts'
          },
          {
            coords: {
              x: 292,
              y: 372,
              w: 108,
              h: 94
            },
            _id: '647782f6b1dcda0013e4ed31',
            name: 'Cysts'
          },
          {
            coords: {
              x: 606,
              y: 332,
              w: 97,
              h: 131
            },
            _id: '647782f6b1dcda0013e4ed30',
            name: 'Infiltrates'
          },
          {
            coords: {
              x: 692,
              y: 448,
              w: 96,
              h: 72
            },
            _id: '647782f6b1dcda0013e4ed2f',
            name: 'Consolidation'
          },
          {
            coords: {
              x: 718,
              y: 538,
              w: 62,
              h: 61
            },
            _id: '647782f6b1dcda0013e4ed2e',
            name: 'Cavity'
          }
        ]
      }
    }
  ],
  phaseTwo: [
    {
      image: '/img/phaseTwo/2.png',
      edai: {
        pathologies: [
          {
            coords: {
              x: 78.813,
              y: 308.432,
              w: 182.225,
              h: 552.446
            },
            name: 'Pneumothorax'
          },
          {
            coords: {
              x: 245.673,
              y: 517.556,
              w: 132.618,
              h: 263.16
            },
            name: 'Consolidation'
          }
        ]
      },
      reference: {
        time: 33177,
        diagnosis: 'Tension pneumothorax',
        pathologies: [
          {
            coords: {
              x: 81,
              y: 296,
              w: 185,
              h: 562
            },
            _id: '6478c1b7b1dcda0013e4ed6d',
            name: 'Pneumothorax'
          },
          {
            coords: {
              x: 766,
              y: 636,
              w: 107,
              h: 174
            },
            _id: '6478c1b7b1dcda0013e4ed6c',
            name: 'Consolidation'
          },
          {
            coords: {
              x: 243,
              y: 538,
              w: 138,
              h: 258
            },
            _id: '6478c1b7b1dcda0013e4ed6b',
            name: 'Consolidation'
          }
        ]
      }
    }
  ]
}

const actions = {}

const mutations = {}

export const challenge = {
  namespaced: true,
  state,
  actions,
  mutations
}
