<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      alert: state => state.alert
    }),
    alertState () {
      return this.alert.counter
    }
  },
  watch: {
    alertState (_new, _old) {
      if (this.alert.type) {
        const _variant = this.alert.type.replace('alert-', '')
        if (_variant !== 'none') {
          let _message = this.alert.message || this.alert.status
          console.log(_message)
          if (!_message) {
            _message = 'There was a technical error processing your request. Please, try again later.'
          }
          this.$bvToast.toast(_message, {
            title: 'RADIFY AI',
            toaster: 'b-toaster-top-right',
            variant: _variant,
            solid: true,
            appendToast: true,
            autoHideDelay: 10000
          })
        }
      }
    }
  }
}
</script>
