import { jwtHelper } from '../helpers'
import { challengerService } from '../services'

const token = localStorage.getItem('token')
const state = {
  completed: false,
  info: {
    name: null,
    email: null,
    mobile: null,
    gender: null,
    gender_other: null,
    age: null,
    job_role: null,
    job_role_other: null,
    years: null,
    participate: true,
    confirm1: false,
    confirm2: false,
    confirm3: false
  },
  status: token ? { loggedIn: true } : {},
  token: token
}

if (token) {
  const _user = jwtHelper.getUser()
  if (_user) {
    state.completed = true
    state.info = _user
  }
}

const actions = {
  update ({ commit }, info) {
    challengerService.save(info)
      .then(challenger => {
        if (challenger) {
          commit('update', challenger)
        }
      })
  },
  updateExtra ({ commit }, info) {
    challengerService.saveExtra(info.id, info.extra)
      .then(challenger => {
        if (challenger) {
          commit('updateExtra', challenger)
        }
      })
  },
  clear ({ commit }) {
    commit('clear')
  }
}

const mutations = {
  update (state, info) {
    if (info.token) {
      state.completed = true
      state.info = info.info
      state.status = { loggedIn: true }
      state.token = info.token
      localStorage.setItem('token', info.token)
    } else {
      state.completed = true
      state.info = info.info
    }
  },
  updateExtra (state, info) {
    if (info.token) {
      state.completed = true
      state.info = info.info
      state.status = { loggedIn: true }
      state.token = info.token
      localStorage.setItem('token', info.token)
    } else {
      state.completed = false
      state.info = {
        name: null,
        email: null,
        mobile: null,
        gender: null,
        gender_other: null,
        age: null,
        job_role: null,
        job_role_other: null,
        years: null,
        participate: true,
        confirm1: false,
        confirm2: false,
        confirm3: false
      }
    }
  },
  clear (state) {
    state.completed = false
    state.info = {
      name: null,
      email: null,
      mobile: null,
      gender: null,
      gender_other: null,
      age: null,
      job_role: null,
      job_role_other: null,
      years: null,
      participate: true,
      confirm1: false,
      confirm2: false,
      confirm3: false
    }
    state.status = {}
    state.token = null
  }
}

export const user = {
  namespaced: true,
  state,
  actions,
  mutations
}
