import { apiRoute, handleResponse } from '../helpers'

export const challengerService = {
  get,
  getAll,
  save,
  saveExtra,
  update
}

function get (identifier) {
  const requestOptions = {
    method: 'GET'
  }

  return fetch(apiRoute() + '/api/v1/challengers/' + identifier, requestOptions).then(handleResponse)
}

function getAll () {
  const requestOptions = {
    method: 'GET'
  }

  return fetch(apiRoute() + '/api/v1/challengers', requestOptions).then(handleResponse)
}

function save (challenger) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(challenger)
  }

  return fetch(apiRoute() + '/api/v1/challengers', requestOptions).then(handleResponse)
}

function saveExtra (id, challenger) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(challenger)
  }

  return fetch(apiRoute() + '/api/v1/challengers/extra/' + id, requestOptions).then(handleResponse)
}

function update (challenger) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(challenger)
  }

  return fetch(apiRoute() + '/api/v1/challengers/' + challenger._id, requestOptions).then(handleResponse)
}
